@import '../../styles/mixins';

.homeArticles {
  background-color: #1f3267;
  padding: 30px 15px;
  @include tablet {
    padding: 50px 0;
  }
  .homeArticlesCols {
    display: grid;
    grid-gap: 30px;
    @include tablet {
      grid-template-columns: 300px auto;
    }
    @include desktop {
      grid-template-columns: 440px auto;
      grid-gap: 40px;
    }
  }
}
