@import '../../styles/mixins';

.homeBanner {
  background-color: #1f3267;
  padding: 50px 0;
  .bannerImageWrapper {
    display: none;
    @include tablet {
      display: block;
    }
  }
  .bannerImageMobileWrapper {
    display: block;
    @include tablet {
      display: none;
    }
  }
  .homeBannerLink {
    display: block;
    overflow: hidden;
    img {
      transition: 0.3s ease-in-out;
    }
    &:hover img {
      transform: scale(1.03);
    }
  }
}
