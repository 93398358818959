@import '../../styles/mixins';

.homeSlider {
  margin-top: -120px;
  .homeSlide {
    background-color: #0c1837;
    display: flex;
    align-items: center;
    min-height: 90vh;
    padding: 120px 0;
    position: relative;
    @include tablet {
      min-height: 80vh;
    }
    @include desktop-lg {
      min-height: 100vh;
    }
    @include desktop-xl {
      min-height: 100vh;
    }
    &::after {
      background: rgb(31, 50, 103);
      background: linear-gradient(
        -266deg,
        rgba(31, 50, 103, 1) 2%,
        rgba(103, 113, 143, 0) 49%,
        rgba(195, 195, 195, 0) 50%,
        rgba(31, 50, 103, 0) 51%
      );
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      pointer-events: none;
    }
    .homeSlideMobileImageWrapper > img,
    .homeSlideImageWrapper > img {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      pointer-events: none;
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
    .homeSlideMobileImageWrapper {
      @include tablet {
        display: none;
      }
    }
    picture ~ picture.homeSlideImageWrapper {
      @media (max-width: $tablet-min-width - 1px) {
        display: none;
      }
    }
    .homeSlideText {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      max-width: 80vw;
      position: relative;
      z-index: 1;
      @include tablet {
        padding: 0 30px;
        max-width: 600px;
      }
      @include desktop {
        max-width: 700px;
      }
      @include desktop-lg {
        padding: 0 30px;
        max-width: 850px;
      }
      .homeSlideHeading {
        // background-color: #0c1837;
        color: #fff;
        font-family: industry;
        font-weight: bold;
        font-style: italic;
        display: inline;
        font-size: 30px;
        line-height: 1.2;
        margin: 0 0 10px;
        padding: 10px 20px;
        @include tablet {
          font-size: 60px;
          margin: 0 0 20px;
          padding: 10px;
        }
      }
      .homeSlideSubheading {
        background-color: #1f3267;
        color: #fff;
        display: inline;
        font-family: industry;
        font-weight: 300;
        font-size: 20px;
        margin: 0;
        padding: 10px;
        padding: 10px 20px;
        @include tablet {
          font-size: 25px;
          padding: 10px;
        }
      }
      .homeSlideActions {
        margin-top: 20px;
        padding-left: 20px;
        @include tablet {
          margin-top: 40px;
        }
      }
    }
  }
}
