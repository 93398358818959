@import '../../styles/mixins';

.articleRow {
  border-bottom: 4px solid #0c1837;
  color: #fff;
  display: grid;
  font-size: 20px;
  font-family: industry;
  font-weight: bold;
  grid-template-columns: 100px auto;
  align-items: center;
  padding: 15px 0;
  position: relative;
  @include tablet {
    border-bottom-width: 6px;
    grid-template-columns: 100px auto 120px;
    padding: 30px 0;
  }
  &:first-child {
    border-top: 4px solid #0c1837;
    @include tablet {
      border-top-width: 6px;
    }
  }
  &::after {
    background-color: $primary;
    content: '';
    height: 4px;
    position: absolute;
    bottom: -5px;
    left: 0;
    width: 0;
    transition: width 0.5s ease-in-out;
  }
  .articleDate {
    color: $primary;
  }
  .articleTitle {
    margin: 0;
    a:hover {
      color: $primary;
    }
  }
  .articleImage {
    display: none;
    height: 72px;
    img {
      display: block;
      height: 72px;
      object-fit: cover;
    }
    @include tablet {
      display: block;
      opacity: 0;
      transition: opacity 0.5s ease-in-out;
    }
  }
  @include tablet {
    &:hover {
      &::after {
        width: 100%;
      }
      .articleImage {
        opacity: 1;
      }
    }
  }
}
