@import '../../styles/mixins';

.homeCategory {
  // aspect-ratio: 1.65;
  display: block;
  // padding: 0 15px;
  position: relative;
  overflow: hidden;
  width: 100%;
  @media (min-width: 500px) {
    padding: 0;
  }
  & > img {
    width: 100%;
    transition: transform 0.3s ease-in-out;
  }
  &:hover > img {
    transform: scale(1.02);
  }
  &::before {
    background: url(/images/right-arrow.svg);
    content: '';
    height: 14px;
    width: 23px;
    position: absolute;
    bottom: 20px;
    right: 20px;
    z-index: 2;
  }
  @include tablet {
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(#0c1837, 0.5);
      pointer-events: none;
      transition: opacity 0.3s ease-in-out;
    }
    &:hover::after {
      opacity: 0;
    }
  }
  .homeCategoryIcon {
    position: absolute;
    top: 20px;
    left: 20px;
    z-index: 2;
  }
  h3 {
    color: #fff;
    font-size: 30px;
    font-family: industry;
    font-weight: 500;
    line-height: 1.33;
    margin: 0;
    position: absolute;
    bottom: 20px;
    left: 20px;
    right: 50px;
    z-index: 2;
  }
}
