@import '../../styles/mixins';

.articleTile {
  background: transparent;
  border: none;
  display: block;
  text-align: left;

  .articleImage {
    display: block;
    margin: 0;
    overflow: hidden;
    img {
      display: block;
      width: 100%;
      transition: transform 0.3s ease-in-out;
    }
    &:hover img {
      transform: scale(1.02);
    }
  }
  .articleDate {
    color: $primary;
    margin-top: 30px;
  }
  .articleTitle {
    color: #fff;
    font-size: 20px;
    font-weight: bold;
    margin: 0;
  }
  .articleText {
    color: #97a0cc;
    font-size: 15px;
    line-height: 2;
    margin-top: 25px;
    a {
      color: $primary;
      text-decoration: underline;
    }
  }
}
