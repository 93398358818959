.productTile {
  display: block;
  // max-width: 60vw;
  .productLayer {
    background: rgb(31, 50, 103);
    background: linear-gradient(
      299deg,
      rgba(31, 50, 103, 1) 0%,
      rgba(16, 25, 52, 1) 100%
    );
    position: relative;

    .productLayerInside {
      background-image: url(/images/product/product-bar.png),
        url(/images/right-arrow.svg);
      background-position: left center, right 20px bottom 20px;
      background-repeat: repeat-y, no-repeat;
      background-size: 25px auto, 20px auto;
      padding: 25px 10px 25px 25px;
    }
  }
  .productImage {
    margin: 0;
    // overflow: hidden;
    padding-top: 125%;
    position: relative;
    img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      max-height: 100%;
      max-width: 100%;
      transition: 0.3s ease-in-out;
    }
  }
  .productTexts {
    padding: 10px 0 20px;
    .productName {
      color: #fff;
      font-family: industry;
      font-size: 20px;
      font-weight: bold;
      margin: 0;
    }
    .productDescription {
      color: #97a0cc;
      font-size: 16px;
      font-weight: 300;
      margin-top: 10px;
    }
  }
  &:hover .productImage img {
    transform: translate(-50%, -50%) scale(1.03);
  }
}
