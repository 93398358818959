@import '../../styles/mixins';

.homeBoxes {
  .homeBox {
    background-color: #1f3267;
    @include desktop {
      border-top: 7px solid #0c1837;
      display: flex;
      align-items: center;
      position: relative;
      &::before {
        background-color: #0c1837;
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 7px;
        z-index: 2;
      }
      &::after {
        background-color: $primary;
        content: '';
        height: 7px;
        width: 7px;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%, -100%);
      }
      &:first-child {
        border-top: 0;
        &::after {
          content: none;
          display: none;
        }
      }
    }
    .homeBoxText {
      padding: 50px 30px;
      max-width: 585px;
      width: 100%;
      h2 {
        color: #fff;
        font-family: industry;
        font-size: 30px;
        font-weight: bold;
        font-style: italic;
        margin: 0 0 40px;
        text-transform: uppercase;
        @include tablet {
          font-size: 40px;
        }
      }
      // .yellowHeading {
      //   display: flex;
      //   align-items: center;
      //   margin-bottom: 40px;
      //   & > span {
      //     background: url(/images/home-box-decor.svg);
      //     background-size: contain;
      //     color: #1f3267;
      //     display: flex;
      //     align-items: center;
      //     justify-content: center;
      //     font-family: industry;
      //     font-size: 40px;
      //     font-style: italic;
      //     font-weight: bold;
      //     flex-shrink: 0;
      //     height: 48px;
      //     margin-right: 15px;
      //     padding-top: 10px;
      //     width: 109px;
      //     @include tablet {
      //       font-size: 80px;
      //       height: 96px;
      //       margin-right: 20px;
      //       padding-top: 20px;
      //       width: 218px;
      //     }
      //   }
      //   .yellowHeadingText {
      //     color: $primary;
      //     font-family: industry;
      //     font-size: 24px;
      //     font-style: italic;
      //     text-transform: uppercase;
      //     @include tablet {
      //       font-size: 40px;
      //     }
      //     & > h2 {
      //       font-weight: bold;
      //       line-height: 1.05;
      //       margin: 0;
      //     }
      //     & > h3 {
      //       font-size: 20px;
      //       font-weight: 300;
      //       line-height: 1;
      //       margin: 0;
      //       @include tablet {
      //         font-size: 36px;
      //       }
      //     }
      //   }
      // }
      h4 {
        color: #fff;
        font-family: industry;
        font-weight: 500;
        font-size: 16px;
        line-height: 1.5;
        margin: 0 0 15px;
        @include tablet {
          font-size: 20px;
        }
      }
      p {
        color: #97a0cc;
        font-size: 15px;
        line-height: 2;
      }
      .homeBoxActions {
        margin-top: 30px;
      }
      @include desktop {
        padding-left: 0;
        padding-right: 0;
        width: 50%;
      }
    }
    @include desktop {
      &:nth-child(even) {
        flex-direction: row-reverse;
        .homeBoxText {
          margin-left: auto;
          padding-right: 50px;
        }
      }
      &:nth-child(odd) {
        .homeBoxText {
          margin-right: auto;
          padding-left: 50px;
        }
      }
    }
    @include desktop {
      &:nth-child(even) .homeBoxText {
        padding-right: 75px;
      }
      &:nth-child(odd) .homeBoxText {
        padding-left: 75px;
      }
    }
    .homeBoxImage {
      align-self: stretch;
      @include desktop {
        width: 50%;
      }
      img {
        display: block;
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }
  }
}
