@import '../../styles/mixins';

.homeProducts {
  padding: 45px 0;
  @include tablet {
    padding: 100px 0;
  }
  .homeProductsLayout {
    @include desktop-lg {
      margin-left: calc(50vw - 585px);
    }
    .homeProductsText {
      padding: 0 40px 30px;
      @include tablet {
        padding: 0 15px 30px;
      }
      h3 {
        color: #fff;
        font-family: industry;
        font-size: 30px;
        font-weight: bold;
        font-style: italic;
        margin: 0 0 40px;
        text-transform: uppercase;
        @include tablet {
          font-size: 40px;
        }
      }
      h4 {
        color: #fff;
        font-family: industry;
        font-size: 16px;
        line-height: 2;
        @include tablet {
          font-size: 20px;
          line-height: 1.5;
        }
      }
      p {
        color: #97a0cc;
        font-size: 15px;
        line-height: 2;
      }
      .homeProductsActions {
        margin-top: 30px;
        margin-left: 10px;
      }
    }
    @include tablet {
      display: grid;
      grid-template-columns: auto 380px;
      .homeProductsText {
        margin-right: 50px;
        padding: 0 15px;
      }
    }
    @include desktop {
      display: flex;
      .homeProductsText {
        width: 400px;
        margin-right: 80px;
      }
      .homeProductsSlider {
        width: calc(100% - 480px);
      }
    }
  }
}
