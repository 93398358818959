@import '../../styles/mixins';

.similarProducts {
  background-color: #04040c;
  padding: 30px;
  @include tablet {
    padding: 50px 30px;
  }
  @include desktop {
    background: url(/images/similar-products-bgr.jpg) center no-repeat;
    background-size: cover;
  }
  @include desktop-lg {
    padding-left: 0;
    padding-right: 0;
  }
  .moreButton {
    display: inline-block;
    @include tablet {
      display: none;
    }
  }
  .similarProductsHeading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 0 50px;
    h2 {
      margin: 0;
    }
    .moreButton {
      display: none;
      @include tablet {
        display: block;
      }
    }
  }
}
